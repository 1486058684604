<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->

        <ProjectInfo />

        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <BodyHeader />
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        <div class="grid" style="padding-top: 20px; justify-content: center">
          <div
            class="col-12 xl:col-12 sm:col-12 md:col-12 .col-12"
            style="text-align: center"
          >
            <div>
              <div
                class="grid"
                style="
                  box-sizing: border-box;
                  background: rgb(255, 255, 255);
                  border: 2px solid rgb(245, 246, 247);
                  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
                  border-radius: 18px;
                  justify-content: center;
                "
              >
                <div class="col-12"></div>
                <div
                  class="col-12 flex"
                  style="justify-content: center; align-items: center"
                >
                  <Image
                    src="/images/locIcon.svg"
                    alt="Image"
                    width="30"
                    style="padding-right: 10px; padding-top: 10px"
                  />
                  <h5
                    style="
                      font-style: normal;
                      font-weight: 600;
                      font-size: 15px;
                      line-height: 18px;
                      color: #466107;
                      display: flex;
                      align-items: center;
                      padding-right: 20px;
                    "
                  >
                    Todas as Áreas
                  </h5>
                  <Image
                    src="/images/logoVeg.svg"
                    alt="Image"
                    width="30"
                    style="padding-right: 10px; padding-top: 10px"
                  />
                  <h5
                    style="
                      font-style: normal;
                      font-weight: 600;
                      font-size: 15px;
                      line-height: 18px;
                      color: #466107;
                      display: flex;
                      align-items: center;
                    "
                  >
                    Vegetativo
                  </h5>
                </div>
                <div
                  class="col-12 xl:col-12 md:col-12 lg:col-12 sm:col-12 .col-12 .col-sm-12"
                  style=""
                >
                  <div class="card">
                    <DataTable
                      :value="products1.data"
                      editMode="row"
                      dataKey="id"
                      v-model:editingRows="editingRows"
                      @row-edit-save="onRowEditSave"
                      responsiveLayout="scroll"
                    >
                      <Column field="name" header="Item" style="width: 20%">
                        <template #editor="{ data, field }">
                          <InputText v-model="data[field]" />
                        </template>
                      </Column>
                      <Column
                        field="inventoryStatus"
                        header="Unidade de Medida"
                        style="width: 20%"
                      >
                        <template #editor="{ data, field }">
                          <Dropdown
                            v-model="data[field]"
                            :options="statuses"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione a unidade de medida"
                          >
                            <template #option="slotProps">
                              <span
                                :class="
                                  'product-badge status-' +
                                  slotProps.option.value.toLowerCase()
                                "
                                >{{ slotProps.option.label }}</span
                              >
                            </template>
                          </Dropdown>
                        </template>
                        <template >
                          slotProps.data.inventoryStatus
                        </template>
                      </Column>
                      <Column field="price" header="Métodos" style="width: 20%">
                        <template #editor="{ data, field }">
                          <InputText v-model="data[field]" />
                        </template>
                      </Column>
                      <Column
                        :rowEditor="true"
                        style="width: 10%; min-width: 8rem"
                        bodyStyle="text-align:center"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectInfo from "@/components/ProjectInfo.vue";
import BodyHeader from "@/components/Attachments/BodyHeader.vue";
import { useProjectsStore } from "@/store/projects";

export default {
  data() {
    return {
      editingRows: [],
      projectsStore: useProjectsStore(),
      selectedType: null,
      loading: true,
      products1: null,
      displayModal: false,
      filterarea: "AllAreas",
      filterType: "Controle",
      filterType2: "Vegetativo",
      products: null,
      statuses: [
        { label: "mg/dm³", value: "INSTOCK" },
        { label: "mg/Kg", value: "LOWSTOCK" },
        { label: "cmolc/dm³", value: "OUTOFSTOCK" },
        { label: "g/Kg M.S", value: "OUTOFSTOCK" },
        { label: "mg/Kg M.S", value: "OUTOFSTOCK" },
        { label: "%", value: "OUTOFSTOCK" },
        { label: "g/kg", value: "OUTOFSTOCK" },
        { label: "g/dm3", value: "OUTOFSTOCK" },
      ],
      // ###################################
    };
  },
  created() {
    this.columns = [
      { field: "code", header: "Fonte" },
      { field: "name", header: "Item" },
      { field: "quantity", header: "Unidade de Medida" },
      { field: "quantity", header: "Métodos" },
      { field: "quantity", header: "Obrigatoriedade" },
    ];

    this.products1 = {
      data: [
        {
          id: "1000",
          code: "Análise solo",
          name: "Textura (granulométrica)",
          description: "Product Description",
          image: "bamboo-watch.jpg",
          price: 65,
          category: "Accessories",
          required: "Obrigatório",
          quantity: 24,
          inventoryStatus: "%",
          rating: 5,
        },
        {
          id: "1001",
          code: "Análise solo",
          name: "pH (h20 ou Cacl2)",
          description: "Product Description",
          image: "black-watch.jpg",
          required: "Obrigatório",
          price: 72,
          category: "Accessories",
          quantity: 61,
          inventoryStatus: "",
          rating: 4,
        },
        {
          id: "1002",
          code: "Análise solo",
          name: "M.O",
          description: "Product Description",
          image: "blue-band.jpg",
          required: "Obrigatório",
          price: 79,
          category: "Fitness",
          quantity: 2,
          inventoryStatus: "%",
          rating: 3,
        },
        {
          id: "1003",
          code: "Análise solo",
          name: "P disponível",
          required: "Obrigatório",
          description: "Product Description",
          image: "blue-t-shirt.jpg",
          price: 29,
          category: "Clothing",
          quantity: 25,
          inventoryStatus: "mg/dm3",
          rating: 5,
        },
        {
          id: "1004",
          code: "Análise solo",
          name: "Ca",
          required: "Obrigatório",
          description: "Product Description",
          image: "bracelet.jpg",
          price: 15,
          category: "Accessories",
          quantity: 73,
          inventoryStatus: "cmolc/dm³",
          rating: 4,
        },
        {
          id: "1005",
          code: "Análise solo",
          name: "Mg",
          description: "Product Description",
          image: "brown-purse.jpg",
          required: "Obrigatório",
          price: 120,
          category: "Accessories",
          quantity: 0,
          inventoryStatus: "cmolc/dm³",
          rating: 4,
        },
        {
          id: "1006",
          code: "Análise solo",
          name: "Al",
          description: "Product Description",
          image: "chakra-bracelet.jpg",
          required: "Obrigatório",
          price: 32,
          category: "Accessories",
          quantity: 5,
          inventoryStatus: "cmolc/dm³",
          rating: 3,
        },
        {
          id: "1007",
          code: "Análise solo",
          name: "K",
          description: "Product Description",
          image: "galaxy-earrings.jpg",
          required: "Obrigatório",
          price: 34,
          category: "Accessories",
          quantity: 23,
          inventoryStatus: "mg/dm³",
          rating: 5,
        },
        {
          id: "1008",
          code: "Análise solo",
          name: "S",
          description: "Product Description",
          image: "game-controller.jpg",
          required: "Obrigatório",
          price: 99,
          category: "Electronics",
          quantity: 2,
          inventoryStatus: "mg/dm³",
          rating: 4,
        },
        {
          id: "1009",
          code: "Análise solo",
          name: "B",
          description: "Product Description",
          image: "gaming-set.jpg",
          required: "Obrigatório",
          price: 299,
          category: "Electronics",
          quantity: 63,
          inventoryStatus: "mg/dm³",
          rating: 3,
        },
        {
          id: "1009",
          code: "Análise solo",
          name: "Cu",
          description: "Product Description",
          image: "gaming-set.jpg",
          required: "Obrigatório",
          price: 299,
          category: "Electronics",
          quantity: 63,
          inventoryStatus: "mg/dm³",
          rating: 3,
        },
        {
          id: "1009",
          code: "Análise solo",
          name: "Fe",
          description: "Product Description",
          image: "gaming-set.jpg",
          required: "Obrigatório",
          price: 299,
          category: "Electronics",
          quantity: 63,
          inventoryStatus: "mg/dm³",
          rating: 3,
        },
        {
          id: "1009",
          code: "Análise solo",
          name: "Mn",
          description: "Product Description",
          image: "gaming-set.jpg",
          required: "Obrigatório",
          price: 299,
          category: "Electronics",
          quantity: 63,
          inventoryStatus: "mg/dm³",
          rating: 3,
        },
        {
          id: "1009",
          code: "Análise solo",
          name: "Zn",
          description: "Product Description",
          image: "gaming-set.jpg",
          required: "Obrigatório",
          price: 299,
          category: "Electronics",
          quantity: 63,
          inventoryStatus: "mg/dm³",
          rating: 3,
        },
        {
          id: "1009",
          code: "Análise solo",
          name: "Nitrato",
          description: "Product Description",
          image: "gaming-set.jpg",
          required: "Opcional",
          price: 299,
          category: "Electronics",
          quantity: 63,
          inventoryStatus: "mg/Kg",
          rating: 3,
        },
        {
          id: "1009",
          code: "Análise solo",
          name: "Amônia",
          description: "Product Description",
          image: "gaming-set.jpg",
          required: "Opcional",
          price: 299,
          category: "Electronics",
          quantity: 63,
          inventoryStatus: "mg/Kg",
          rating: 3,
        },
        {
          id: "1009",
          code: "Análise solo",
          name: "Na",
          description: "Product Description",
          image: "gaming-set.jpg",
          required: "Opcional",
          price: 299,
          category: "Electronics",
          quantity: 63,
          inventoryStatus: "mg/dm³",
          rating: 3,
        },
      ],
    };
    console.log(this.products1);
  },
  mounted() {},
  methods: {
    onCellEditComplete(event) {
      let { data, newValue, field } = event;

      switch (field) {
        case "quantity":
        case "price":
          if (this.isPositiveInteger(newValue)) data[field] = newValue;
          else event.preventDefault();
          break;

        default:
          if (newValue.trim().length > 0) data[field] = newValue;
          else event.preventDefault();
          break;
      }
    },
    isPositiveInteger(val) {
      let str = String(val);
      str = str.trim();
      if (!str) {
        return false;
      }
      str = str.replace(/^0+/, "") || "0";
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },
    onRowEditSave(event) {
      let { newData, index } = event;

      this.products2[index] = newData;
    },
    getStatusLabel(status) {
      switch (status) {
        case "INSTOCK":
          return "In Stock";

        case "LOWSTOCK":
          return "Low Stock";

        case "OUTOFSTOCK":
          return "Out of Stock";

        default:
          return "NA";
      }
    },
  },
  beforeUnmount() {},
  components: {
    ProjectInfo: ProjectInfo,
    BodyHeader: BodyHeader,
  },
};
</script>

<style>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-datatable .p-datatable-header {
  background: #90a956;
  color: #ffff;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 700;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 2px 0;
  font-weight: 600;
  color: #fff;
  background: #90a956;
  transition: box-shadow 0.15s;
}
.p-button {
  color: #000000;
  background: #ffffff;
  border: 1px solid #90a956;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.p-datatable .p-datatable-footer {
  background: #90a956;
  color: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 700;
}
</style>
